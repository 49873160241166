<template>
    <b-modal id="add-to-contact-modal" title="Simpan Kontak">
        <div class="modal-body">
            <div class="wrapp-btn-filter" style="margin-bottom: 20px">
                <a class="btn" v-bind:class="{ 'btn-open': !isNewContact, 'btn-closed': isNewContact }"
                    @click="showContactList">Gabungkan</a>
                <a class="btn" v-bind:class="{ 'btn-open': isNewContact, 'btn-closed': !isNewContact }"
                    @click="showNewContactForm">Buat Baru</a>
            </div>
            <form class="form-horizontal form-material">
                <div v-if="isNewContact" class="form-group">
                    <div class="col-md-12 m-b-20">
                        <input type="text" class="form-control" v-model="contactName" placeholder="Nama Kontak">
                        <div class="form-control-feedback text-danger">
                        </div>
                    </div>
                    <div class="col-md-12 m-b-20">
                        <input type="text" class="form-control" v-model="contactId" placeholder="ID Kontak">
                        <div class="form-control-feedback text-danger">
                        </div>
                    </div>
                    <div class="col-md-12 m-b-20">
                        <input type="text" readonly class="form-control" v-bind:value="phoneNumber"
                            placeholder="Nomor Telepon">
                        <div class="form-control-feedback text-danger">
                        </div>
                    </div>
                </div>
                <div v-else class="form-horizontal">
                    <div class="form-material">
                        <div class="input-group ">
                            <input type="text" class="form-control" id="uname" placeholder="Masukkan kata pencarian..."
                                v-model="searchTerm" @input="isTyping = true">
                            <div class="input-group-addon">
                                <i class="ti-search"></i>
                            </div>
                        </div>
                    </div>
                    <div id="contact-lookup-scrollbar" data-scrollbar="true" tabindex="1"
                        style="overflow: hidden; outline: none;">
                        <div class="scroll-content">
                            <ul class="chatonline user-list">
                                <li v-for="item in contactList" class="active" :key="item.id">
                                    <a href="#" class="active" v-bind:class="{ 'active': item.selected }"
                                        @click="selectContact($event, item)">
                                        <span class="chat-info"> <span class="user-name">{{ item.name }} <i
                                                    v-if="item.selected" class="success ti-check"></i> </span>
                                            <small class="text-default">
                                                <div class="chat-content-thumb">{{ item.primaryPhoneNumber }}</div>
                                            </small>

                                        </span>

                                    </a>
                                </li>
                                <li id="contact-lookup-loader-indicator" v-bind:class="{'d-none':  !isLoading}">
                                     <b-spinner small variant="secondary" label="Loading..."></b-spinner>
                                </li>
                            </ul>
                        </div>
                        <div class="scrollbar-track scrollbar-track-x" style="display: none;">
                            <div class="scrollbar-thumb scrollbar-thumb-x"
                                style="width: 329px; transform: translate3d(0px, 0px, 0px);"></div>
                        </div>
                        <div class="scrollbar-track scrollbar-track-y" style="display: block;">
                            <div class="scrollbar-thumb scrollbar-thumb-y"
                                style="height: 20px; transform: translate3d(0px, 0px, 0px);"></div>
                        </div>
                    </div>
                </div>
            </form>
            <ErrorMessage v-bind:error="error" />
        </div>
        <template v-slot:modal-footer>
            <button type="button" class="btn btn-default" data-dismiss="modal">Batal</button>
            <button type="button" @click="submit" class="btn btn-primary tst3"><i v-if="saveOnProgress"
                    class="fa fa-circle-o-notch fa-spin"></i>&nbsp;Simpan</button>
        </template>
    </b-modal>
</template>
<script>
    import axios from 'axios'
    import ErrorMessage from '../components/ErrorMessage'
    var debounce = require('lodash.debounce');
    var {
        toNationalAppNumber
    } = require('../utils/phonenumber-utils');
    import {
        mapState
    } from 'vuex'

    var CONTACT_LOOKUP_ROW_PER_PAGE = 25;

    export default {
        computed: {
            ...mapState({
                sessionInfo: state => state.session.sessionInfo
            })
        },
        data: function () {
            return {
                error: {},
                isTyping: false,
                saveOnProgress: false,
                isNewContact: false,
                contactId: '',
                contactName: '',
                queryParams: {
                    status: 'open',
                    sortOrder: 'lastActive'
                },
                currentPage: 0,
                hasMoreData: false,
                searchTerm: '',
                contactList: [],
                phoneNumbers: [],
                isLoading: false,
            }
        },
        watch: {
            contactName: function (val, old) {
                if (old == '' || this.contactId == old.replace(/[\-\s]/g, "_").replace(/[^a-zA-Z0-9_]/g, "")) {
                    this.contactId = val.replace(/[\-\s]/g, "_").replace(/[^a-zA-Z0-9_]/g, "");
                }
            },
            phoneNumber: function () {
                this.contactId = ''
                this.contactName = ''
                try {
                    this.searchTerm = toNationalAppNumber(this.phoneNumber, this.sessionInfo.defaultCountryCode)
                } catch (e) {
                    // empty
                }
            },
            searchTerm: debounce(function () {
                this.isTyping = false;
            }, 1000),
            isTyping: function (value) {
                if (!value) {
                    this.loadContact();
                }
            }
        },
        methods: {
            loadContact: async function () {
                this.contactList = [];
                this.currentPage = 1;
                this.hasMoreData = true;
                this.isLoading = true;
                this.error = {}
                try {
                    var resp = await axios.get(process.env.VUE_APP_API_URL + '/contact/', {
                        params: {
                            page: this.currentPage,
                            term: this.searchTerm,
                            size: CONTACT_LOOKUP_ROW_PER_PAGE
                        }
                    });

                    var jsonData = resp.data;
                    this.hasMoreData = jsonData.page.number < jsonData.page.totalPages;
                    this.contactList = jsonData.content;
                } catch (error) {
                    this.error = this.$helpers.createError(error)
                }
                this.isLoading = false;
            },
            selectContact(event, selected) {
                event.preventDefault()
                this.contactList = this.contactList.map((item) => {
                    if (item.id == selected.id) {
                        item.selected = true;
                    } else {
                        item.selected = false;
                    }
                    return item
                })
            },
            showNewContactForm: function () {
                this.isNewContact = true
            },
            showContactList: function () {
                this.isNewContact = false
            },
            submit: async function () {
                this.error = {};

                if (this.isNewContact) {
                    var postData = {
                        id: this.contactId,
                        primaryPhoneNumber: this.phoneNumber,
                        name: this.contactName,
                        contactStatus: 'ACTIVE',
                        communicatorChannels: [{
                            number: this.phoneNumber,
                            wa: true,
                            sms: true
                        }],
                        sessionId: this.sessionId
                    };

                    this.saveOnProgress = true;
                    try {
                        await axios.post(process.env.VUE_APP_API_URL + '/contact/', postData)
                        this.$bvModal.hide('add-to-contact-modal')
                        this.$emit('save-callback', true, this.contactId, postData)
                    } catch (error) {
                        this.error = this.$helpers.createError(error)
                        this.$emit('save-callback', false, error)
                    }
                    this.saveOnProgress = false;
                } else {
                    var foundDocs = this.contactList.find(item => item.selected)
                    if (!foundDocs) {
                        this.error = {
                            html: 'Pilih tujuan penggabungan data kontak'
                        }
                        this.$emit('save-callback', false)
                    } else {
                        var putData = {
                            personId: foundDocs.id,
                            commContactId: this.addedContactId
                        }

                        this.saveOnProgress = true;
                        try {
                            await axios.put(process.env.VUE_APP_API_URL + '/contact/merge/', putData)
                            this.$bvModal.hide('add-to-contact-modal')
                            this.$emit('save-callback', true, foundDocs.id)
                        } catch (error) {
                            this.error = this.$helpers.createError(error)
                            this.$emit('save-callback', false, error)
                        }
                        this.saveOnProgress = false;
                    }
                }
            }
        },
        props: ['phoneNumber', 'addedContactId', 'sessionId'],
        components: {
            ErrorMessage
        }
    }
</script>