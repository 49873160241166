<template>
	<div>
		<ul v-if="!showConversation && !showSearchMessages && !showFilter" class="chatonline style-none">
			<li v-for="item in contactList" :key="item.id">
				<input v-if="showCloseChatMany" type="checkbox" :id="'check-' + item.id" 
					class="select-chat-end pull-left" :name="'check-' + item.id"
					@input="selectCloseChatMany(item, $event)" />
				<div class="list-conversation" :class="{ active: item.selected }" @click="selectContact(item)">
					<div class="wrapp-img">
						<span v-if="item.profilePicUrl">
							<img :src="item.profilePicUrl" alt="user-img" class="img-circle"
								@error="handleImageError($event, 'contact')">
						</span>
						<span v-else>
							<div class="initials" v-if="item.name && item.name.includes('+')">
								<i class="mdi" :class="{ 
									'mdi-account-multiple': item.isGroupContact == true || item.isGroupContact == 'true',
									'mdi-account': item.isGroupContact == false || item.isGroupContact == 'false' || !item.isGroupContact,
								}"></i>
							</div>
							<div class="initials" v-if="item.name && !item.name.includes('+')">
								{{ $helpers.changeInitialName(item.name) }}
							</div>
						</span>
						<span class="is-affiliator" v-if="item.isAffiliator" title="Afiliator">
							<i class="text-affiliator mdi mdi-link-variant "></i>
						</span>
					</div>
					<i v-if="item.stars && (page == 'my-conversation' || page == 'all-message')" 
						class="mb-1 mdi mdi-star text-warning pull-right" 
						style="font-size:14px"></i>
					<span class="chat-info">
						<span :class="{ 'read-message': item.isRead, 'unread-message': !item.isRead }">
							{{ item.name }}
						</span>
						<span v-if="item.parentAccountId" :class="{ 'read-message': item.isRead, 'unread-message': !item.isRead }">
							- {{ item.parentAccountId }}
						</span>
						<br>
						<small class="text-default mt-1">
							<div class="chat-content-thumb user-info" :class="{ 'read-message': item.isRead, 'unread-message': !item.isRead }">
								{{ item.lastMessagesText }}
							</div>
						</small>
						<span v-if="item.customerRole && item.customerRole != 'common'" class="badge badge-pill badge-success text-white mr-1">
							<v-icon style="font-size:10px !important" class="text-white" left>
								mdi-account
							</v-icon>
							{{ item.customerRole }}
						</span>
						<span v-if="item.labels">
							<v-tooltip v-if="item.labels.length > 0" bottom>
								<template v-slot:activator="{ on, attrs }">
									<span class="mr-1 badge badge-pill badge-secondary text-white" v-bind="attrs"
										v-on="on">
										<v-icon style="font-size:10px !important" class="text-white" left>
											mdi-tag
										</v-icon>
										+{{ item.labels.length }}
									</span>
								</template>
								<div v-for="(item) in item.labels" :key="item">
									<span>
										<v-icon class="icon-sort-filter text-white" left>
											mdi-tag
										</v-icon>
										{{ item }}
									</span>
								</div>
							</v-tooltip>
						</span>
					</span>
					<span class="right-content-chat user-info">
						<small class="chat_time" :class="{ 'read-message': item.isRead, 'unread-message': !item.isRead }">
							{{ item.time }}
						</small>
						<span v-if="item.status == 'open' && !item.currentOperatorName && page == 'all-message'" 
							class="badge badge-pill badge-primary text-white label-primary mr-5">
							Belum ditangani
						</span>
						<small class="text-primary" v-if="page == 'all-message'" >
							<div class="chat-content-thumb">
								{{ item.currentOperatorName }}
							</div>
						</small>
						<span v-if="item.lastMessagesDirection == 'IN' && (page == 'my-conversation' || page == 'all-message')" 
							title="Chat belum dibalas">
							<i class="mdi mdi-information-outline text-warning"></i>
						</span>
					</span>
					<div class="clearfix"></div>
				</div>
			</li>
		</ul>
		<ul v-if="showSearchMessages && !showFilter" class="chatonline style-none inThisChatWrapp">
			<div class="message-result text-muted font-weight-bold">
				<small class="font-weight-bold label-group">
					Messages
				</small>
			</div>
			<li id="contact-loader-indicator" v-bind:class="{'d-none': !msgUiFlag.isLoading}">
				<b-spinner small variant="secondary" label="Loading..."></b-spinner>
			</li>
			<li v-for="(item, index) in searchMessageList.global" :key="index">
				<router-link class="router-link-exact-active router-link-active" :to="{
					name: page == 'all-message' ? 'allmessage' : page == 'unassigned' ? 'unassigned' : 'myconversation', 
					params: {id: encodeURIComponent(item.contactId)}, 
					query: {status: 'open',sortOrder: 'lastActive', chatId: encodeURIComponent(item._id)} 
				}">
					<small class="right-content-chat-conversation">
						<span class="chat_time">
							{{ item.time }}
							{{ item.contactId }}
						</span>
					</small>
					<div v-if="contactUiFlag.kind == 'order'" class="search-result-conversation">
						<span v-for="note in item.internalNotes" :key="note.id">
							<span v-if="note.contentType == 'order' || note.contentType == 'return'">
								{{ note.list.join(',') }}
							</span>
						</span>
					</div>
					<div class="search-result-conversation" v-else>
						{{ item.text }}
					</div>
				</router-link>
			</li>
		</ul>
		<ul v-if="showConversation && !showFilter" class="chatonline style-none inThisChatWrapp">
			<div class="message-result text-muted font-weight-bold">
				<small class="font-weight-bold label-group">
					Pesan
				</small>
			</div>
			<li id="contact-loader-indicator" class="ml-4 text-center" :class="{ 'd-none': !msgUiFlag.isLoading }">
				<b-spinner class="mb-4 " small variant="secondary" label="Loading..."></b-spinner>
			</li>
			<li v-for="(item, index) in searchMessageList.personal" :key="index">
				<a class="router-link-exact-active router-link-active" @click="jumpToElement(item.originId || item._id)">
					<small class="right-content-chat-conversation">
						<span class="chat_time">
							{{ item.time }}
						</span>
					</small>
					<div class="search-result-conversation">
						{{ item.text }}
					</div>
				</a>
			</li>
		</ul>
	</div>
</template>
<script>

import IMAGE_ACC from '../../public/images/dummy.jpg'
import IMAGE_MESSAGE from '../../public/images/image-not-found.png'

export default {

	props: [
		'page', 'contactList', 'searchMessageList', 'msgUiFlag', 'contactUiFlag',
		'showCloseChatMany', 'showConversation', 'showSearchMessages', 'showFilter'
	],

	data: function() {
		return {

		}
	},

	methods: {
		jumpToElement(messageId) {
			this.$emit('jumpToElement', messageId)
		},

		selectCloseChatMany(contact, event) {
			this.$emit('selectCloseChatMany', contact, event)
		},

		selectContact(contact) {
			this.$emit('selectContact', contact)
		},

		handleImageError: function (event, type) {
			if (type == 'contact') event.target.src = IMAGE_ACC;
			if (type == 'message') event.target.src = IMAGE_MESSAGE;
		},
	}
}
</script>