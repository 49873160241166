const isBlank = require('is-blank');
const {
    toFriendlyDateTime
} = require('../date-utils');
var moment = require('moment');
const {
    MODULES
} = require('../const')

var axios = require('axios')

var removeContactFromList = (contactList, removedId) => {
    contactList.splice(contactList.findIndex(contact => {
        return contact.id === removedId;
    }), 1);
}
var updateContactList = async (sourceModule, contactList, updatedContact, updatedContactId, currentSort, currentSearch, allowAddLast, currentOperator, currentStatus, labels, roles , lastMessagesDirection) => {
    const exist = contactList.map(e => e.id).indexOf(updatedContactId)

    if(exist >= 0) {
        let temp = contactList[exist]
        temp.time = toFriendlyDateTime(updatedContact.lastActive)
        temp.lastMessagesDirection = updatedContact.lastMessagesDirection
        temp.lastMessagesText = updatedContact.lastMessagesText
        temp.lastActive = updatedContact.lastActive
        temp.isRead = updatedContact.isRead
        temp.currentOperatorId = updatedContact.currentOperatorId
        temp.currentOperatorName = updatedContact.currentOperatorName
        temp.status = updatedContact.status
        temp.stars = updatedContact.stars
        temp.isPaused = updatedContact.isPaused
        temp.pausedOn = updatedContact.pausedOn ? moment(updatedContact.pausedOn).toISOString() : null
        updatedContact = temp
    } else {
        updatedContact = Object.assign({
            "id": updatedContactId,
            time: toFriendlyDateTime(updatedContact.lastActive)
        }, updatedContact);
    }

    var isBetweenRange = (updatedContact, contactList, currentSort) => {
        if (currentSort == 'lastActive') {
            return contactList[0].lastActive >= updatedContact.lastActive &&
                contactList[contactList.length - 1].lastActive <= updatedContact.lastActive;
        } else {
            return contactList[0].name.toLowerCase().localeCompare(updatedContact.name.toLowerCase()) <= 0 &&
                contactList[contactList.length - 1].name.toLowerCase().localeCompare(updatedContact.name.toLowerCase()) >= 0;
        }
    }

    var sortFn = (a, b) => {
        if (currentSort == 'lastActive') {
            return moment(b.lastActive) - moment(a.lastActive);
        } else {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        }
    }

    var isIncludeLabel = () => {
        if (labels.length > 0) {
            return updatedContact.labels.some(e => labels.includes(e))
        }

        return true
    }

    let role = true

    var isIncludeRoles = async () => {

        if (roles.length > 0) {
            const id = updatedContact.parentAccountId ? updatedContact.parentAccountId : updatedContactId
            const { data } = await axios.get(process.env.VUE_APP_API_URL + '/contact/id/' + id);
            return roles.includes(data.customerRole)
        }
    
        return true
    }

    role = await isIncludeRoles()

    var foundIdx = contactList.findIndex(contact => {
        return contact.id === updatedContactId;
    });

    const isLastMessagesDirection = lastMessagesDirection == "" ? true : updatedContact.lastMessagesDirection == lastMessagesDirection ? true : false
    const isNameEqualSearchTerm = currentSearch == "" ? true : updatedContact.name.toLowerCase().match(currentSearch.toLowerCase())

    if ((sourceModule == MODULES.ALL_MESSAGES && currentStatus != updatedContact.status) ||
        (sourceModule == MODULES.MY_CONVERSATION && (currentOperator != updatedContact.currentOperatorId || currentStatus != updatedContact.status)) ||
        (sourceModule == MODULES.UNASSIGNED && updatedContact.currentOperatorId != undefined)) {
        if (foundIdx >= 0) {
            contactList.splice(foundIdx, 1);
        }

        return foundIdx >= 0 ? 'removed' : null;
    } else {
        if (foundIdx >= 0 && isIncludeLabel() && role && isLastMessagesDirection && isNameEqualSearchTerm) {
            if(sourceModule == MODULES.UNASSIGNED && updatedContact.currentOperatorId ) {
                contactList.splice(foundIdx, 1);
            } else if(sourceModule == MODULES.UNASSIGNED && !updatedContact.currentOperatorId) {
                updatedContact.selected = contactList[foundIdx].selected;
                contactList.splice(foundIdx, 1, updatedContact);
            } else if(sourceModule == MODULES.MY_CONVERSATION && updatedContact.status == 'closed') {
                contactList.splice(foundIdx, 1);
            } else if(sourceModule == MODULES.MY_CONVERSATION) {
                updatedContact.selected = contactList[foundIdx].selected;
                contactList.splice(foundIdx, 1, updatedContact);
            } else if(sourceModule == MODULES.ALL_MESSAGES) {
                updatedContact.selected = contactList[foundIdx].selected;
                contactList.splice(foundIdx, 1, updatedContact);
            }
            contactList.sort(sortFn);

            return 'changed';
        } else if ((isBlank(currentSearch) || updatedContact.name.toLowerCase().indexOf(currentSearch.toLowerCase()) >= 0) && isIncludeLabel() && role && isLastMessagesDirection && isNameEqualSearchTerm) {
            if (contactList.length == 0 || (currentSort == 'lastActive' && moment(updatedContact.lastActive).isAfter(contactList[0].lastActive)) ||
                (currentSort == 'name' && updatedContact.name.toLowerCase().localeCompare(contactList[0].name.toLowerCase()) < 0)) {
                contactList.splice(0, 0, updatedContact);
                return 'added-at-first';
            } else if (isBetweenRange(updatedContact, contactList, currentSort)) {
                contactList.push(updatedContact);
                contactList.sort(sortFn);
            } else if (allowAddLast) {
                contactList.splice(contactList.length, 0, updatedContact);
                return 'added-at-last';
            }
        } else {
            return null;
        }
    }

}

exports.updateContactList = updateContactList;
exports.removeContactFromList = removeContactFromList;