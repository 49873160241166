<template>
    <v-dialog v-model="showDialog" persistent @keydown.esc="closeDialog" transition="dialog-bottom-transition"
        max-width="600">
        <template>
            <v-card>
                <v-toolbar color="deep-purple accent-4" class="text-h6 text-white" style="background-color:red">
                    <span class="text-white">Tambahkan Catatan</span>
                </v-toolbar>
                <v-form v-model="formValid">
                    <v-card-text class="">
                        <div class="mb-4 mt-2" v-if="selectedType == 'PENJUALAN' || selectedType == 'PENGEMBALIAN'">
                            <div class="mb-2"><span class="text-muted">Order:</span> <b><br>{{ summaryOrderList }}</b></div> 
                            <div class="mb-4"><span class="text-muted">Retur:</span> <b><br>{{ summaryReturnList }}</b></div>
                        </div>
                        <div class="clearfix"></div>
                        <v-select class="mt-4" v-model="selectedType" :items="items" label="Tipe Catatan"></v-select>

                        <v-textarea v-if="selectedType == 'TEXT'"
                        class="" label="Tambah Catatan" v-model="note" counter maxlength="1000" 
                        :rules="rules.required"></v-textarea>

                        <v-combobox class="" v-model="selectedOrder" v-if="selectedType == 'PENJUALAN'"
                            label="Tambah Catatan Penjualan" hide-selected multiple persistent-hint
                            hint="Tekan enter untuk menyimpan" small-chips :rules="rules.requiredArray">
                            <template v-slot:selection="{ item }">
                                <div class="mt-1 mr-1 badge badge-pill badge-secondary text-white">
                                    <span>
                                        <v-icon style="font-size:10px !important" class="text-white" left>mdi-tag
                                        </v-icon>
                                    </span>
                                    <span>{{ item }}</span>
                                    <v-icon style="font-size:10px !important" class="text-white" right
                                        @click="deleteSelected(item)">
                                        mdi-close $delete
                                    </v-icon>
                                </div>
                            </template>
                            <template v-slot:item="{ item }">
                                <div class="row">
                                    <div class="col-md-10">
                                        <span class="pull-left">{{ item }}</span>
                                    </div>
                                    <div class="col-md-2">
                                        <button class="pull-right btn btn-danger btn-sm"
                                            @click="deleteSelected(item)">Hapus</button>
                                    </div>
                                </div>
                            </template>
                        </v-combobox>

                        <v-combobox class="" v-model="selectedReturn" v-if="selectedType == 'PENGEMBALIAN'"
                            label="Tambah Catatan Pengembalian" hide-selected multiple persistent-hint
                            hint="Tekan enter untuk menyimpan" small-chips :rules="rules.requiredArray">
                            <template v-slot:selection="{ item }">
                                <div class="mt-1 mr-1 badge badge-pill badge-secondary text-white">
                                    <span>
                                        <v-icon style="font-size:10px !important" class="text-white" left>
                                            mdi-tag</v-icon>
                                    </span>
                                    <span>{{ item }}</span>
                                    <v-icon style="font-size:10px !important" class="text-white" right
                                        @click="deleteSelected(item)">
                                        mdi-close $delete
                                    </v-icon>
                                </div>
                            </template>
                            <template v-slot:item="{ item }">
                                <div class="row">
                                    <div class="col-md-10">
                                        <span class="pull-left">{{ item }}</span>
                                    </div>
                                    <div class="col-md-2">
                                        <button class="pull-right btn btn-danger btn-sm"
                                            @click="deleteSelected(item)">Hapus</button>
                                    </div>
                                </div>
                            </template>
                        </v-combobox>

                        <v-select v-if="selectedType == 'KOMPLAIN'"
                            v-model="selectedComplainType"
                            :items="complainList"
                            attach
                            chips
                            label="Tipe Komplain"
                            multiple
                            :rules="rules.requiredArray"
                            :disabled="selectedType == 'KOMPLAIN' && currentComplain.kind.length > 0"
                        ></v-select>

                        <v-textarea v-if="selectedType == 'KOMPLAIN'"
                        class="" label="Tambah Catatan" v-model="noteComplain" counter maxlength="1000" 
                        :rules="rules.required" :disabled="selectedType == 'KOMPLAIN' && currentComplain.kind.length > 0"></v-textarea>

                        <v-select v-if="selectedType == 'AGEN'"
                            v-model="selectedAgentType"
                            :items="agentList"
                            item-text="text" 
                            item-value="value"
                            attach
                            chips
                            label="Tipe Agen"
                            :disabled="Object.keys(tempCurrentAgent).length > 0"
                        ></v-select>

                        <v-textarea v-if="selectedType == 'AGEN'" :disabled="Object.keys(tempCurrentAgent).length > 0"
                        class="" label="Tambah Catatan" v-model="noteAgent" counter maxlength="1000" 
                        :rules="rules.required"></v-textarea>

                        <div v-if="selectedType == 'PRODUK'">
                            <v-form v-model="formValidProduct">
                                <v-autocomplete
                                    v-model="selectedInputProduct"
                                    class="" :loading="isLoadingProduct"
                                    :items="inputProductList"
                                    :search-input.sync="searchInputProduct"
                                    :no-filter="true"
                                    label="Produk"
                                    item-value="id"
                                    hide-no-data
                                    @keyup.enter="searchProduct"
                                >
                                    <template v-slot:selection="{ item }">
                                        <span class="ml-2">
                                            {{ item.localSku }} - {{ item.name }} {{ item.size }} {{ item.color }}
                                        </span>
                                    </template>
                                    <template v-slot:item="{ item }" >
                                        <div class="d-block" style="width:100%">
                                            {{ item.localSku }} - {{ item.name }} {{ item.size }} {{ item.color }}
                                        </div>
                                    </template>
                                </v-autocomplete>

                                <v-text-field
                                    v-if="selectedInputProduct"
                                    v-model="productQty"
                                    label="Kuantitas"
                                    :rules="rules.required"
                                    class="" />
    
                                <v-btn v-if="selectedInputProduct" :disabled="!formValidProduct"
                                    depressed color="deep-purple accent-4" @click="addProduct">
                                    <span class="text-white">Tambah</span>
                                </v-btn>
                            </v-form>

                            <v-simple-table v-if="productList.length > 0">
                                <thead>
                                    <tr>
                                        <th class="text-left">Kode Produk</th>
                                        <th class="text-left">Kuantitas</th>
                                        <th class="text-left">Ukuran</th>
                                        <th class="text-left">Warna</th>
                                        <th class="text-left">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in productList" :key="item.name">
                                        <td>{{ item.localSku }}</td>
                                        <td>{{ item.qty }} {{ item.qty > 1 ? 'pcs' : 'pc'}}</td>
                                        <td>{{ item.size }}</td>
                                        <td>{{ item.color }}</td>
                                        <td>
                                            <v-icon color="red" @click="deleteProductSelected(item)">mdi-delete</v-icon>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </div>

                        <ErrorMessage v-bind:error="error" />
                    </v-card-text>
                    <v-card-actions class="justify-end p-3">
                        <v-btn class="" @click="closeDialog" :loading="isLoading">
                            Kembali
                        </v-btn>
                        <v-btn class="ml-3 btn btn-primary" style="color: white;" :loading="isLoading"
                            :disabled="!formValid || 
                            (currentComplain.kind.length > 0 && selectedType == 'KOMPLAIN') || 
                            (Object.keys(tempCurrentAgent).length > 0 && selectedType == 'AGEN') ||
                            (productList.length < 1 && selectedType == 'PRODUK')" @click="submit">
                            Simpan
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </template>
    </v-dialog>
</template>

<script>
    import ErrorMessage from '../components/ErrorMessage'
    import axios from 'axios'

    export default {
        data: function () {
            return {
                uploadPercentage: 0,
                productQty: 0,

                error: '',
                selectedType: "TEXT",

                note: String(),
                noteAgent: String(),
                noteComplain: String(),
                summaryOrderList: String(),
                summaryReturnList: String(),
                selectedAgentType: String(),
                searchInputProduct: String(),

                selectedInputProduct: String(),
                
                complainList: ['PRODUK_CACAT', 'PENGIRIMAN', 'WEBSITE', 'APLIKASI', 'PELAYANAN_TIDAK_SESUAI', 'STOK_PRODUK', 'POIN', 'RETUR', 'PROMO', 'PEMBAYARAN', 'LAINNYA'],
                items: ['TEXT', 'PENJUALAN', 'PENGEMBALIAN', 'KOMPLAIN', 'AGEN', 'PRODUK'],
                agentList: [
                    { text: 'PERTANYAAN', value: 'INQUIRY' },
                    { text: 'REGISTRASI', value: 'REGISTRATION' },
                    { text: 'PEMBELANJAAN', value: 'SHOPPING' },
                    { text: 'DEPOSIT', value: 'DEPOSIT' },
                ],

                currentComplain: {
                    kind: []
                },

                tempCurrentAgent: Object(), 

                productList: Array(),
                selectedComplainType: Array(),
                selectedOrder: Array(),
                selectedReturn: Array(),
                inputProductList: Array(),

                isLoadingProduct: false,
                isLoading: false,
                formValid: false,
                formValidProduct: false,

                rules: {
                    required: [(v) => !!v || ""],
                    requiredObject: [(v) => Object.keys(v).length > 0 || ""],
                    requiredArray: [(v) => v.length > 0 || ""],
                    counter: v => v.length <= 1000 || 'maksimum 1000 karakter',
                },
            }
        },
        methods: {
            addProduct: function() {
                const productList = [...this.productList]
                const index = productList.findIndex(e => e.id == this.selectedInputProduct)

                if(index >= 0) {
                    productList[index].qty = productList[index].qty + parseInt(this.productQty)
                    this.productList = productList
                } else {
                    const idx = this.inputProductList.findIndex(e => e.id == this.selectedInputProduct)
                    const product = this.inputProductList[idx]
                    this.productList = [
                        { 
                            id: product.id,
                            localSku: product.localSku, 
                            qty: parseInt(this.productQty),
                            size: product.size,
                            color: product.color,
                        },
                        ...this.productList
                    ]
                }
                
                this.selectedInputProduct = String()
                this.productQty = 0
            },
            searchProduct: async function () {
                this.isLoadingProduct = true
                try {
                    var {
                        data
                    } = await axios.get(process.env.VUE_APP_API_URL + '/cmrc/product/', {
                        params: {
                            term: this.searchInputProduct,
                            size: 15,
                            sort: 'localSku,asc',
                            statusMask: 'ACTIVE_ONLY',
                        }
                    });

                    const temp = Array()

                    data.content.forEach(product => {
                        if(product.type == 'VARIED') {
                            product.variants.forEach(variant => {
                                const data = {
                                    id: variant.id,
                                    localSku: product.localSku,
                                    name: product.name,
                                    color: null,
                                    size: null
                                }

                                if(variant.optionProperties) {
                                    if(variant.optionProperties.base_color) data.color = variant.optionProperties.base_color[0].displayValue
                                    if(variant.optionProperties.base_size) data.size = variant.optionProperties.base_size[0].displayValue
                                }

                                temp.push(data)
                            })
                        } else {
                            temp.push({
                                id: product.id,
                                localSku: product.localSku,
                                name: product.name
                            })
                        }
                    })

                    this.inputProductList = temp
                } catch (error) {
                    console.log("search-input-product", error);
                }
                this.isLoadingProduct = false
            },
            deleteProductSelected: function (item) {
                this.productList = this.productList.filter(e => e.id != item.id) 
            },
            deleteSelected: function (item) {
                if (this.selectedType == 'PENJUALAN') {
                    this.selectedOrder = this.selectedOrder.filter(e => e != item)
                } else {
                    this.selectedReturn = this.selectedReturn.filter(e => e != item)
                }
            },
            resetModal: function () {
                this.error = {}
                this.selectedType = "TEXT"
                this.isLoading = false
                this.note = String()
                this.noteComplain = String()
                this.noteAgent = String()
                this.selectedOrder = Array()
                this.selectedReturn = Array()
                this.formValid = false
                this.selectedComplainType = Array()
                this.selectedAgentType = String()
                this.tempCurrentAgent = Object()
                this.currentComplain = {
                    kind: []
                },
                this.selectedInputProduct = String()
                this.productQty = 0
                this.inputProductList = Array()
                this.searchInputProduct = String()
                this.productList = Array()
            },
            submit: async function () {
                this.isLoading = true

                let data = {
                    contentType: this.selectedType == 'TEXT' ? 'text' : 
                        this.selectedType == 'PENJUALAN' ? 'order' : 
                        this.selectedType == 'PENGEMBALIAN' ? 'return' : 
                        this.selectedType == 'AGEN' ? 'agent' :
                        this.selectedType == 'PRODUK' ? 'product' : 'complain',
                    sessionInfoId: this.sessionId
                }

                if (this.selectedType == 'TEXT') data.note = this.note
                if (this.selectedType == 'PENJUALAN') data.list = this.selectedOrder
                if (this.selectedType == 'PENGEMBALIAN') data.list = this.selectedReturn
                if (this.selectedType == 'KOMPLAIN') {
                    data.note = this.noteComplain 
                    data.kind = this.formatSelectedComplainType(this.selectedComplainType)
                }
                if (this.selectedType == 'AGEN') {
                    data.note = this.noteAgent 
                    data.list = [this.selectedAgentType]
                }
                if (this.selectedType == 'PRODUK') {
                    data.productList = this.productList.map(e => {
                        return {
                            localSku: e.localSku,
                            name: e.name,
                            qty: e.qty,
                            color: e.color,
                            size: e.size
                        }
                    })
                }

                try {
                    await axios.post(process.env.VUE_APP_API_URL + '/message/' + this.contactId + '/' + this
                        .messageId + '/note', data)

                    this.closeDialog()
                } catch (error) {
                    this.error = this.$helpers.createError(error)
                }

                this.isLoading = false;
            },
            closeDialog: function () {
                this.resetModal()
                this.$emit('close-dialog', false)
            },
            formatSelectedComplainType(items) {
                return items.map(e => {
                    if (e == 'PRODUK_CACAT') return 'DEFECT'
                    if (e == 'PENGIRIMAN') return 'SHIPPING'
                    if (e == 'WEBSITE') return 'WEBSITE'
                    if (e == 'APLIKASI') return 'APPS'
                    if (e == 'PELAYANAN_TIDAK_SESUAI') return 'CS_BAD_SERVICE'
                    if (e == 'STOK_PRODUK') return 'STOCK'
                    if (e == 'POIN') return 'POINT'
                    if (e == 'RETUR') return 'RETURN'
                    if (e == 'PROMO') return 'PROMO'
                    if (e == 'PEMBAYARAN') return 'PAYMENT'
                    if (e == 'LAINNYA') return 'OTHER'
                })
            },
            formatSelectedComplainTypeEng(items) {
                return items.map(e => {
                    if (e == 'DEFECT') return 'PRODUK_CACAT'
                    if (e == 'SHIPPING') return 'PENGIRIMAN'
                    if (e == 'WEBSITE') return 'WEBSITE'
                    if (e == 'APPS') return 'APLIKASI'
                    if (e == 'CS_BAD_SERVICE') return 'PELAYANAN_TIDAK_SESUAI'
                    if (e == 'STOCK') return 'STOK_PRODUK'
                    if (e == 'POINT') return 'POIN'
                    if (e == 'RETURN') return 'RETUR'
                    if (e == 'PROMO') return 'PROMO'
                    if (e == 'PAYMENT') return 'PEMBAYARAN'
                    if (e == 'OTHER') return 'LAINNYA'
                })
            },
            getSummary: async function(sessionId) {
                this.isLoading = true
                try {
                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/sessioninfo/detail/' + sessionId, {
                        sort: 'createdOn'
                    });
    
                    if(data) {
                        this.summaryOrderList = data.salesOrders.map(e => e.formalId).join(', ')
                        this.summaryReturnList = data.returnOrders.map(e => e.formalId).join(', ')
                    }
                    
                } catch (error) {
                    console.log("get session", error);
                    this.error = this.$helpers.createError(error)
                }
                this.isLoading = false
            },
        },
        created() {

        },
        watch: {
            showDialog: function(val) {
                if(val) {
                    if(this.sessionId) this.getSummary(this.sessionId)
                }
            },
            currentNote: function(val) {
                if(val) {
                    this.selectedType = 'TEXT'
                    const tempCurrentComplain = val.find(e => e.contentType == 'complain')
                    const tempCurrentAgent = val.find(e => e.contentType == 'agent')
                    if(tempCurrentComplain) {
                        this.currentComplain = tempCurrentComplain
                        this.selectedComplainType = this.formatSelectedComplainTypeEng(tempCurrentComplain.kind)
                        this.noteComplain = tempCurrentComplain.note
                    }

                    if(tempCurrentAgent) {
                        this.tempCurrentAgent = tempCurrentAgent
                        this.selectedAgentType = tempCurrentAgent.list.length > 0 ? tempCurrentAgent.list[0] : ""
                        this.noteAgent = tempCurrentAgent.note
                    }
                }
            },
                
        },

        props: [
            'contactId', 'messageId', 'currentNote', 'showDialog', 'sessionId'
        ],

        components: {
            ErrorMessage
        }
    }
</script>